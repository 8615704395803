import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ValidationUtil } from '@app/common/util/validation.util';
import { Login2State } from '@app/selectors/system/login2.select';
 import { getInformationByUserId, loginUserid2 } from '@app/actions/system/login2.action';

import { PageHeadingState, showPageHeading } from './selectors/page-heading.selector';
import { ConvertUtil } from './common/util/convert.util';

import { Member } from '@app/models/myoffice/member/member.model';
import { CommonConstant } from './common/constant/common.constant';
import { SessionService } from './services/system/session.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'PurpleU';
  member$ = new Observable<Member>();

  showPageHeading$ = new Observable<boolean>();

  constructor(private _pageHeadingStore: Store<PageHeadingState>,
              private loginStore: Store<Login2State>,
              private sessionService : SessionService) {
    this.showPageHeading$ = this._pageHeadingStore.select(showPageHeading);
  }

  ngOnInit(): void {
    const urlParams = new URLSearchParams(window.location.search);
    const userId2 = urlParams.get('userid2');
    const userId = urlParams.get('userid');
    const ordNoTmp = urlParams.get('ordNoTmp');

    this.sessionService.setItem(CommonConstant.USER_ID2, JSON.stringify(userId2));
    this.sessionService.setItem(CommonConstant.USER_ID, JSON.stringify(userId));
    this.sessionService.setItem(CommonConstant.LINK_GUEST, CommonConstant.NO);

    if(ValidationUtil.isNotNullAndNotEmpty(userId) && ValidationUtil.isNotNullAndNotEmpty(ordNoTmp)) {
      this.sessionService.removeItem(CommonConstant.LINK_CART);
      this.sessionService.removeItem(CommonConstant.LINK_CART_PRODUCTS);
      this.sessionService.setItem(CommonConstant.LINK_GUEST, CommonConstant.NO);
    } else if(ValidationUtil.isNotNullAndNotEmpty(userId) && ValidationUtil.isNullOrEmpty(ordNoTmp)) {
      this.sessionService.removeItem(CommonConstant.LINK_CART);
      this.sessionService.removeItem(CommonConstant.LINK_CART_PRODUCTS);
      this.sessionService.setItem(CommonConstant.LINK_GUEST, CommonConstant.YES);
      this.loginStore.dispatch(getInformationByUserId(ConvertUtil.convertToString(userId)));
    }

    if(ValidationUtil.isNotNullAndNotEmpty(userId2)) {
      this.sessionService.removeItem(CommonConstant.LINK_CART);
      this.sessionService.removeItem(CommonConstant.LINK_CART_PRODUCTS);
      this.sessionService.setItem(CommonConstant.LINK_GUEST, CommonConstant.YES);
      this.loginStore.dispatch(loginUserid2(ConvertUtil.convertToString(userId2)));
    }
  }
}
